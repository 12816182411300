<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content bgFFF">
      <h2 class="title">
        <div class="title_icon"></div>
        <!-- {{isEdit?'修改':'添加'}}角色信息 -->
        商户功能管理
      </h2>
      <!-- <div class='roleName' v-if='isEdit'>当前编辑角色：{{managerRoleName}}</div> -->
      <!-- <TitleModule :title="'添加/修改角色信息'"></TitleModule> -->
      <!--搜索条件区域-->

      <el-row>
        <el-form
          label-position="right"
          @submit.native.prevent
          label-width="100px"
          :model="formInline"
          style=""
          :rules="rules"
          ref="form"
        >
          <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationName">
            <!-- <el-input :maxlength="20" v-model.trim="formInline.operationName" ></el-input> -->
            <span v-text="formInline.operationName"></span>
          </el-form-item>
          <!-- <el-form-item label="超级管理员">
							    <el-radio-group v-model.trim="formInline.isAdmin" filterable placeholder="">
							       <el-radio :label="value.code + ''" v-for='value in isAdmin' :key='value.code'>{{value.desc}}</el-radio>
							    </el-radio-group>
							</el-form-item> -->
          <el-form-item :label="$t('searchModule.remarks')">
            <el-input :maxlength="20" v-model.trim="formInline.desc"></el-input>
          </el-form-item>
          <div class="all">
            <el-button @click="setAuthority(true)">全部选择</el-button>
            <el-button @click="setAuthority(false)">全部取消</el-button>
          </div>
          <el-form-item label="绑定功能">
            <el-tree ref="tree" :data="sourceData" @check="nodeClickHandle" :props="treeProp" show-checkbox node-key="code">
            </el-tree>
          </el-form-item>
        </el-form>
      </el-row>
      <div style="text-align: center; margin-top: 20px; ">
        <el-button type="primary" @click="save" style="width: 88px;">{{ $t('button.preservation') }}</el-button>
        <el-button type="" @click="$router.push('/operatorFunctionManage')" style="width: 88px;">取消</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import treeCon from "../../../components/tree/";
import {} from "../../../common/js/public.js";
import TitleModule from "../../../components/titleModule/titleModule";
export default {
  name: "hello",
  data() {
    let checkRoleName = (rule, value, callback) => {
      let opt = {
        url: "/boss/2.0/system/checkManagerRoleName",
        method: "post",
        data: {
          managerRoleName: value
        },
        success: res => {
          if (res.state == 12 && this.managerRoleName == value) {
            callback();
          } else if (res.state == 12) {
            callback(new Error("该角色名称已经存在"));
          } else if (res.state == 13) {
            callback();
          } else {
            callback(new Error(res.desc));
          }
        }
      };
      this.$request(opt);
    };
    return {
      selected: [],
      operationInfo: "",
      isAdmin: [],
      isEdit: false,
      sourceData: [],
      resourceList: [],
      managerRoleName: "",
      operationId: "",
      treeProp: {
        children: "children",
        label: "desc"
      },
      formInline: {
        operationName: "",
        operationId: "",
        desc: "",
        resourceIds: "",
        isAdmin: "0"
      },
      rules: {
        managerRoleName: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur"
          },
          { validator: checkRoleName, trigger: "blur" }
        ],
        readOnly: [
          {
            required: true,
            message: "请选择是否只读",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    setAuthority(isChecked) {
      let arr = [];
      if (isChecked) {
        this.sourceData.forEach(v => {
          arr.push(v.code);
        });
      } else {
        arr = this.$refs.tree.getCheckedKeys();
      }
      arr.forEach(v => {
        this.$nextTick(() => {
          this.$refs.tree.setChecked(v, isChecked, true);
        });
      });
    },
    getIsAdmin() {
      let opt = {
        method: "get",
        url: "/acb/2.0/managerRoles/isAdmin",
        success: res => {
          if (res.state == 0) {
            this.isAdmin = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine')
            });
          }
        }
      };
      this.$request(opt);
    },
    nodeClickHandle(data) {
      if (data.function && data.function == "query") {
        let curNode = this.$refs.tree.getNode(data.code);
        if (!curNode.checked) {
          let parentNode = curNode.parent;
          this.$refs.tree.setChecked(parentNode.data, false, true);
        }
      } else if (data.function && data.function != "query") {
        let curNode = this.$refs.tree.getNode(data.code);
        if (curNode.checked) {
          let parentNode = curNode.parent;
          let childNodes = parentNode.childNodes;
          let queryNode = childNodes.find(v => {
            return v.data.function == "query";
          });
          if (!queryNode.checked) {
            this.$refs.tree.setChecked(queryNode.data, true, false);
          }
        }
      }
    },
    handleSourceData(value) {
       let arr = [];
        Object.keys(value).forEach((key) => {
          switch (key) {
            case "PARKING_BUSINESS":
              arr[0] = ({
                code: key,
                desc: '路内停车管理系统',
                children: value[key]
              })
              break;
            case "PARKING_SYSTEM":
              arr[1] = ({
                code: key,
                desc: '路外停车管理系统',
                children: value[key]
              })
              break;
            case "CHARGING_BUSINESS":
              arr[2] = ({
                code: key,
                desc: '充电管理系统',
                children: value[key]
              })
              break;
            case "SYSTEM_MANAGEMENT":
              arr[3] = ({
                code: key,
                desc: '系统管理',
                children: value[key]
              })
              break;
            default:
              break;
          }
        })
        return arr;
    },
    // 获取菜单
    getResources() {
      this.$axios.get("/acb/2.0/systems/loginUser/operationResources").then(res => {
        if (res.state == 0) {
          this.sourceData = this.handleSourceData(res.value);
        } else {
          this.$alert("获取角色权限失败", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    getRoleResource() {
      this.$axios.get("/acb/2.0/operationRoleManager/getByOperationId/" + this.formInline.operationId).then(res => {
        if (res.state == 0) {
          if (res.value) {
            this.operationInfo = res.value;
            this.$refs.tree.setCheckedKeys(res.value.resourceIds.split(","));
          }
        } else {
          this.$alert("获取角色权限失败", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine')
          });
        }
      });
    },
    save() {
      // 获取已选择的权限
      this.formInline.resourceIds = this.$refs.tree.getCheckedKeys(true).join(",");
      if (this.formInline.resourceIds == "") {
        this.$alert("必须选择权限", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
        return;
      }
      if (!this.formInline.desc) {
        this.formInline.desc = "";
      }
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (!this.isEdit || !this.operationInfo.operationManagerRoleId) {
            this.formInline.operationManagerRoleName = this.formInline.operationName;
            this.$axios
              .post("/acb/2.0/operationRoleManager/createOperationRole", {
                data: { ...this.formInline, description: this.formInline.desc }
              })
              .then(res => {
                if (res.state == 0) {
                  this.$router.push("/operatorFunctionManage");
                } else {
                  this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine')
                  });
                }
              });
          } else {
            this.$axios
              .post("/acb/2.0/operationRoleManager/update", {
                data: {
                  operationManagerRoleId: this.operationInfo.operationManagerRoleId,
                  operationManagerRoleName: this.operationInfo.operationManagerRoleName,
                  operationId: this.formInline.operationId,
                  resourceIds: this.formInline.resourceIds,
                  description: this.formInline.desc
                }
              })
              .then(res => {
                if (res.state == 0) {
                  this.$router.push("/operatorFunctionManage");
                } else {
                  this.$alert(res.desc, this.$t('pop_up.Tips'), {
                    confirmButtonText: this.$t('pop_up.Determine')
                  });
                }
              });
          }
        } else {
        }
      });
    }
  },
  components: {},
  created() {
    if (this.$route.query.operationId) {
      this.isEdit = true;
      this.formInline = Object.keys(this.formInline).reduce((pre, key) => {
        pre[key] = this.$route.query[key];
        return pre;
      }, {});
      this.getRoleResource();
    }
    this.getResources();
    // this.getRoleResource();
  },
  mounted() {},
  computed: {}
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.searchWrapper
  .el-row
    width 50%
.breadcrumb
  height 35px
.content

  overflow hidden
  border 1px solid #C0CCDA
  border-radius 4px
  padding 20px
  width 100%
  .el-tree {
  padding-left: 20px;
  color: #1f2d3d;
}
.el-tree {
  padding-left: 20px;
  color: #1f2d3d;
}
>>> .el-tree > .el-tree-node > .el-tree-node__content:first-child {
  padding: 4px 0;
  margin-bottom: 10px;
  background-color: #f4f7fa;
}
.all
  margin-left 100px
  margin-bottom 20px
.roleName
  margin-bottom 20px
  color gray
.el-col-offset-6
  margin 0
.el-col-6
  float none
</style>
