<template>
<div>
  <el-button @click='setCheckedKeys(allArr)'>全选全部</el-button>
  <el-button @click='setCheckedKeys(readOnly)'>全选只读</el-button>
  <el-tree ref='tree' :data="data3" @check-change='nodeClick' show-checkbox node-key="id" default-expand-all style="margin-top:20px">
  </el-tree>
</div>
</template>

<script>
export default {
  data() {
    return {
      allArr: [],
      readOnly: [],
      brother: '',
      data3: [{
        id: 1,
        label: '一级 2',
        children: [{
          id: 3,
          label: '二级 2-1',
          children: [{
            id: 4,
            label: '三级 3-1-1'
                }, {
            id: 5,
            label: '三级 3-1-2',
            disabled: true
                }]
            }, {
          id: 2,
          label: '二级 2-2',
          disabled: true,
          children: [{
            id: 6,
            label: '三级 3-2-1'
                }, {
            id: 7,
            label: '三级 3-2-2',
            disabled: true
                }]
            }]
            }]
    }
  },
  props: ['sourceData'],
  //  {
  //     selected: {
  //         type: Array,
  //         default: function () {
  //             return []
  //         }
  //     },
  //     sourceData: {
  //         type: Array,
  //         default: function () {
  //             return []
  //         }
  //     }
  // },
  methods: {
    getCheckedKeys() {
      return this.$refs.tree.getCheckedKeys(true);
    },
    setCheckedKeys(keysArr) {
      this.$refs.tree.setCheckedKeys(keysArr);
    },
    findAllAndReadOnly(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].label == '全部') {
          this.allArr.push(data[i].id)
        }
        if (data[i].label == '只读') {
          this.readOnly.push(data[i].id)
          break;
        } else {
          data[i].children.length ? this.findAllAndReadOnly(data[i].children) : '';
        }
      }
    },
    findBrother(data, id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == id) {
          this.brother = data[i];
          break;
        } else {
          data[i].children.length ? this.findBrother(data[i].children, id) : '';
        }
      }
    },
    nodeClick(data, isSelected) {
      let brotherCode = this.checkCode(data.id);
      this.findBrother(this.data3, brotherCode);
      if (this.brother.toString() !== "[object Object]") return;
      if (data.id.length < 8) return;
      if (isSelected) {
        let brotherNode = this.$refs.tree.getNode(this.brother);
        brotherNode.checked = false;
      }
    },
    checkCode(code) {
      code = code.toString();
      let len = code.length;
      if (code[len - 1] == 0) {
        return code.substr(0, len - 1) + '1';
      } else {
        return code.substr(0, len - 1) + '0';
      }
    },
    setChildren(child, children) {
      child.id = child.id + '';
      child.id = child.id.substr(0, 7)
      children.push({
        id: child.id + '1',
        label: '全部',
        disabled: false,
        children: []
      })
      children.push({
        id: child.id + '0',
        label: '只读',
        disabled: false,
        children: []
      })
    },
    setTree(parentArr, data) {
      parentArr.forEach(parent => {
        data.forEach(child => {
          if (child.parentCode == parent.id) {
            child.code = child.code.toString()
            parent.children.push({
              id: child.code,
              label: child.desc,
              disabled: true,
              children: []
            })
          }
        });
        if (parent.children.length == 0) {
          // 没有孩子则设置权限
          this.setChildren(parent, parent.children);
        } else {
          // 有孩子继续找孩子的孩子
          this.setTree(parent.children, data);
        }
      })
    }
  },
  watch: {
    sourceData(newData, oldData) {
      let parentArr = [];
      newData.forEach(v => {
        if (!v.parentCode) {
          v.code = v.code.toString()
          parentArr.push({
            id: v.code.substr(0, 7),
            label: v.desc,
            disabled: true,
            children: []
          })
        }
      });
      this.setTree(parentArr, newData);
      this.data3 = parentArr;
      this.findAllAndReadOnly(this.data3)
    }
  },
  created() {
    let parentArr = [];
    this.sourceData.forEach(v => {
      if (!v.parentCode) {
        v.code = v.code.toString()
        parentArr.push({
          id: v.code.substr(0, 7),
          label: v.desc,
          disabled: true,
          children: []
        })
      }
    });
    this.setTree(parentArr, this.sourceData);
    this.data3 = parentArr;
  }
}
</script>
